.buy-btn {
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9em;
  text-align: center;
  position: relative;
  top: -65px;

  	&:hover {
		font-style: oblique;
		letter-spacing: 0.8px;
		transition: 350ms ease-in-out;
    cursor: pointer;
	}
}
