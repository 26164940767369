html,
html * {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-size: 1.05em;
	font-family: Baskerville;
}

a {
	color: rgb(24, 24, 24);	
	text-decoration: none;
}
