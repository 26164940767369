.urbanPointer {
    position: relative;
	margin-top: 62px;
    text-align: center;
    color: white;
	z-index: 1;
	width: 100%;
	height: 500px;
	overflow: hidden;
}

.urbanPointer img {
	position: absolute;
	object-fit: cover;
	top: 0;
	left: 0;
	width: 100%;
	height:500px;
}

.culturePointer {
	position: relative;
	margin-top: 0;
    text-align: center;
    color: white;
	z-index: 1;
	width: 100%;
	height: 500px;
	overflow: hidden;	
}
.culturePointer img {
	position: absolute;
	object-fit: cover;
	top: 0;
	left: 0;
	width: 100%;
	height:500px;
}

.peoplePointer {
	position: relative;
	margin-top: 0;
    text-align: center;
    color: white;
	z-index: 1;
	width: 100%;
	height: 500px;
	overflow: hidden;	
}
.peoplePointer img {
	position: absolute;
	object-fit: cover;
	top: 0;
	left: 0;
	width: 100%;
	height:500px;
}

.naturePointer {
	position: relative;
	margin-top: 0;
    text-align: center;
    color: white;
	z-index: 1;
	width: 100%;
	height: 500px;
	overflow: hidden;
	margin-bottom: 85px;
}
.naturePointer img {
	position: absolute;
	object-fit: cover;
	top: 0;
	left: 0;
	width: 100%;
	height:500px;
}

.bottom-left {
  position: absolute;
	font-size: 1.5em;
  bottom: 36px;
  left: 44px;
	letter-spacing: 1.8px;
}

.overlay {
	display: block;
	background: black;
}

img:hover {
	opacity: 0.8;
	transition: 300ms ease-in-out;
}
