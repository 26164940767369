.squareImage {
	margin-bottom: 0;
	z-index: 1;
	width: 40% ;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;	
}
.squareImage img {
	object-fit: contain;
	width:100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 75px;
	margin-top:100px;
}

.next {
	font-family: Baskerville;
	font-size: 1.29em;
	text-align: center;	
	text-decoration: none;
	color: black;

	&:hover {
		font-style: oblique;
		letter-spacing: 1.3px;
		transition-duration: 500ms
	}
}

.page-title {
	font-size: 1.4em;
	letter-spacing: 1.5px;;
	margin-top: 200px;
	margin-bottom: 30px;
	text-align: center;
	color:black;
}
