.about{
	text-align: center;	
	margin-bottom: 50px;
}

.about-content {
	margin-right: 30%;
	margin-left: 30%;
}

.about-content p {
	margin-top: 20px;
	letter-spacing: 0.4px;
	font-size: 0.9em;
	text-align: center;
	line-height: 200%
}

.contact-title {
	font-size: 1.4em;
	letter-spacing: 1.5px;;
	margin-top: 150px;
	margin-bottom: 100px;
	text-align: center;
	color:black;
}
