.footer-style{
	margin-top: 70px;
	margin-bottom: 80px;
}

.instagram-logo {
	margin-right: 10px;
	&:hover {
		opacity: 0.7;
		transition: 200ms ease-in-out
	}
}

.email-logo {
	margin-left: 10px;
	&:hover {
		opacity: 0.7;
		transition: 200ms ease-in-out
	}
}
